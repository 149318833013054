// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import "./assets/scss/index.scss";
import axios from "axios";
import global from "./assets/scripts/global";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import MyHeader from "./components/common/Header";
import VueI18n from "vue-i18n";

Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.mixin(global);
Vue.use(ElementUI);
Vue.component("MyHeader", MyHeader);

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'en', // 设置地区
  messages: {
    en: require("./assets/lang/en") // 英文语言包
  }
});
/* eslint-disable no-new */
const app = new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
