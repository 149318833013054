<template>
  <div class="pc-container contact-us-container">
    <my-header :fixed="true" :white="true"></my-header>
    <div class="banner"></div>
    <div class="contact-address">
      <p class="email"><img src="../assets/images/email.png" alt="">admin@efun.info</p>
      <p class="ps">{{ $t("contact.service_tips") }}</p>
    </div>
    <div class="line"></div>
    <div class="contact-form">
      <el-form :rules="rules" :model="operationInfo" ref="operationInfo">
        <el-form-item>
          <el-input placeholder="Company Name" v-model="operationInfo.companyName"></el-input>
        </el-form-item>
        <el-form-item prop="name">
          <el-input placeholder="*Name" v-model="operationInfo.name"></el-input>
        </el-form-item>
        <el-form-item prop="telephone">
          <el-input placeholder="*Tel" v-model="operationInfo.telephone"></el-input>
        </el-form-item>
        <el-form-item prop="email">
          <el-input placeholder="*Email" v-model="operationInfo.email"></el-input>
        </el-form-item>
        <el-form-item prop="message">
          <el-input placeholder="*Message" type="textarea" row="5" v-model="operationInfo.message"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="form-footer">
            <div class="btn" @click="confirm">Send</div>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <div class="success-modal" :style="{display: visible ? 'block' : 'none'}">
      <div class="content">
        <div class="body">
          <p class="title">Thank you for your inquiry!</p>
          <p class="desc">Thank you for choosing us from many companies, the person in charge will contact you later.</p>
        </div>
        <div class="footer">
          <div class="btn" @click="visible=false">OK</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ContactUs',
    data() {
      return {
        operationInfo: {
          companyName: '',
          name: '',
          telephone: '',
          email: '',
          message: ''
        },
        rules: {
          name: [{required: true, message: '请输入您的姓名', trigger: 'change'}],
          telephone: [{required: true, message: '请输入您的电话号码', trigger: 'change'}],
          email: [{required: true, message: '请输入您的邮箱', trigger: 'change'}],
          message: [{required: true, message: '请输入您的留言', trigger: 'change'}],
        },
        visible : false,
      }
    },
    methods: {
      confirm() {
        this.$refs['operationInfo'].validate((valid)=>{
          if(valid){
            console.log(this.operationInfo)
            this.$post('saveContact', this.operationInfo).then(resp => {
              this.visible = true
            }).catch(err => {
              this.$message({
                type: 'error',
                message: 'error submit'
              })
            })
          }else{
            console.log('error submit')
          }
        })
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
