<template>
  <div class="pc-container about-us-container">
    <my-header :fixed="true" :white="true"></my-header>
    <div class="banner"></div>
    <div class="detail-content">
      <div class="title">Company Profile</div>
      <div class="title-decoration"></div>
      <div class="company-profile">
        <div class="logo"><img src="../assets/images/efun_logo.png" alt=""></div>
        <div class="info">
          <p>{{ $t("index.company_intro_des") }}</p>
          <p>{{ $t("index.company_intro_des2") }}</p>
          <p>{{ $t("index.company_intro_des3") }}</p>
          <p>{{ $t("index.company_intro_des4") }}</p>
          <p>{{ $t("index.company_intro_des5") }}</p>
          <p>{{ $t("index.company_intro_des6") }}</p>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="detail-content">
      <div class="title">Team Profile</div>
      <div class="title-decoration"></div>
      <div class="team-profile">
        <div class="profile-item">
          <div class="profile-percentage">88<span>%</span></div>
          <div class="profile-title">{{ $t("about.dev_team") }}</div>
          <div class="profile-subtitle">{{ $t("about.dev_team_des") }}</div>
        </div>
        <div class="profile-item">
          <div class="profile-percentage">90<span>%</span></div>
          <div class="profile-title">{{ $t("about.teaml_level") }}</div>
          <div class="profile-subtitle">{{ $t("about.teaml_level_des") }}</div>
        </div>
        <div class="profile-item">
          <div class="profile-percentage">92<span>%</span></div>
          <div class="profile-title">{{ $t("about.background") }}</div>
          <div class="profile-subtitle">{{ $t("about.background_des") }}</div>
        </div>
        <div class="profile-item">
          <div class="profile-percentage">95<span>%</span></div>
          <div class="profile-title">{{ $t("about.experience") }}</div>
          <div class="profile-subtitle">{{ $t("about.experience_des") }}</div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="detail-content">
      <div class="title">Company Advantage</div>
      <div class="title-decoration"></div>
      <div class="company-advantages">
        <div class="company-advantage">
          <div class="icon"><img src="../assets/images/img_ca_1.jpg" alt=""></div>
          <div class="advantage-title">{{ $t("about.service_quality") }}</div>
          <div class="advantage-subtitle">{{ $t("about.service_quality_des") }}</div>
        </div>
        <div class="company-advantage">
          <div class="icon"><img src="../assets/images/img_ca_2.jpg" alt=""></div>
          <div class="advantage-title">{{ $t("about.ability") }}</div>
          <div class="advantage-subtitle">{{ $t("about.ability_des") }}</div>
        </div>
        <div class="company-advantage">
          <div class="icon"><img src="../assets/images/img_ca_3.jpg" alt=""></div>
          <div class="advantage-title">{{ $t("about.management") }}</div>
          <div class="advantage-subtitle">{{ $t("about.management_des") }}</div>
        </div>
        <div class="company-advantage">
          <div class="icon"><img src="../assets/images/img_ca_4.jpg" alt=""></div>
          <div class="advantage-title">{{ $t("about.communication") }}</div>
          <div class="advantage-subtitle">{{ $t("about.communication_des") }}</div>
        </div>
        <div class="company-advantage">
          <div class="icon"><img src="../assets/images/img_ca_5.jpg" alt=""></div>
          <div class="advantage-title">{{ $t("about.cooperative") }}</div>
          <div class="advantage-subtitle">{{ $t("about.cooperative_des") }}</div>
        </div>
        <div class="company-advantage">
          <div class="icon"><img src="../assets/images/img_ca_6.jpg" alt=""></div>
          <div class="advantage-title">{{ $t("about.project_exp") }}</div>
          <div class="advantage-subtitle">{{ $t("about.project_exp_des") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutUs',
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
