import _ from 'lodash'
import Q from 'q'
const mixin = {
  data() {
    return {
      scrollTop: 0,
      offsetHeight: 0,
    }
  },

  created() {
    if (this.$route && this.$route.meta && this.$route.meta.title) {
      document.title = this.$route.meta.title
    }
  },

  mounted() {
    window.addEventListener('scroll', () => {
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.offsetHeight = document.documentElement.scrollHeight || document.body.scrollHeight
    })
  },

  computed: {
    appEnv() {
      return process.env.NODE_ENV;
    },
    api() {
      return process.env.API_ROOT;
    },
    webApi() {
      return process.env.WEB_API_ROOT;
    },
    clientWidth() {
      return document.documentElement.clientWidth
    },
    clientHeight() {
      return document.documentElement.clientHeight
    },
  },

  methods: {
    $req(method, url, params) {
      let defer = Q.defer();
      params = params || {};
      url = `${this.api}${url}`;
      const noData = (method === 'get' || method === 'delete');
      const headers = {};
      this.$http[method](url, (noData ? {
        headers,
        params: _.omitBy(params, _.isNull),
        body: params,
        emulateJSON: true
      } : params), (noData ? '' : {
        headers
      })).then(resp => {
        if (resp.status === 200) {
          if (resp.data.code === 0) {
            resp.data.data = resp.data.data || {};
            defer.resolve(resp.data.data);
          } else {
            let message = resp.data && resp.data.message;
            defer.reject(message);
          }
        } else {
          defer.reject('请求失败，请重试')
        }
      }).catch(err => {
        defer.reject(err);
      })

      return defer.promise;
    },

    $get(url, params) {
      if (params) {
        let arr = []
        for (let p in params) {
          arr.push(`${p}=${params[p]}`)
        }
        url = `${url}?${arr.join('&')}`
      }
      return this.$req('get', url)
    },

    $put(url, params) {
      return this.$req('put', url, params)
    },

    $patch(url, params) {
      return this.$req('patch', url, params)
    },

    $post(url, params) {
      return this.$req('post', url, params)
    },

    $del(url, params) {
      if (params) {
        let arr = []
        for (let p in params) {
          arr.push(`${p}=${params[p]}`)
        }
        url = `${url}?${arr.join('&')}`
      }
      return this.$req('delete', url)
    },

    setCookie(name, value, days = 7) {
      var d = new Date
      d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days)
      window.document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString()
    },

    getCookie(name) {
      var v = window.document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)')
      return v ? v[2] : null
    },

    delCookie(name) {
      this.setCookie(name, '', -1)
    },

    setStorage(name, value) {
      localStorage.setItem(name, value)
    },

    removeStorage(name) {
      localStorage.removeItem(name)
    },

    getStorage(name) {
      return localStorage.getItem(name)
    },

    clearStorage() {
      localStorage.clear()
    },

    back() {
      this.$router.go(-1);
    },
  }
}
export default mixin
