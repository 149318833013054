<template>
  <div class="pc-container service-container">
    <my-header :fixed="true" :white="true"></my-header>
    <div class="banner"></div>
    <div class="detail-content">
      <div class="title">Service Content</div>
      <div class="sub-title">{{ $t('service.title') }}</div>
      <div class="title-decoration"></div>
      <div class="content">
        <div class="items">
          <div class="item">
            <div class="icon"><img src="../assets/images/icon-h5.png" alt=""></div>
            <div class="title">{{ $t('service.h5_dev') }}</div>
            <div class="desc">{{ $t('service.h5_dev_des') }}</div>
          </div>
          <div class="item">
            <div class="icon"><img src="../assets/images/icon-website.png" alt=""></div>
            <div class="title">{{ $t('service.web_dev') }}</div>
            <div class="desc">{{ $t('service.web_dev_des') }}</div>
          </div>
          <div class="item">
            <div class="icon"><img src="../assets/images/icon-app.png" alt=""></div>
            <div class="title">{{ $t('service.app_dev') }}</div>
            <div class="desc">{{ $t('service.app_dev_des') }}</div>
          </div>
          <div class="item">
            <div class="icon"><img src="../assets/images/icon-desktop.png" alt=""></div>
            <div class="title">{{ $t('service.software_dev') }}</div>
            <div class="desc">{{ $t('service.software_dev_des') }}</div>
          </div>
          <div class="item">
            <div class="icon"><img src="../assets/images/icon-system.png" alt=""></div>
            <div class="title">{{ $t('service.management_dev') }}</div>
            <div class="desc">{{ $t('service.management_dev_des') }}</div>
          </div>
          <div class="item">
            <div class="icon"><img src="../assets/images/icon-solution.png" alt=""></div>
            <div class="title">{{ $t('service.it_solution') }}</div>
            <div class="desc">{{ $t('service.it_solution_des') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="detail-content">
      <div class="title">Service Process</div>
      <div class="sub-title">{{ $t('service.service_process_des') }}</div>
      <div class="title-decoration"></div>
      <div class="content">
        <div class="steps">
          <div class="step">
            <div class="icon icon1"></div>
            <div class="title">{{ $t('service.custome_service') }}</div>
            <div class="tips">
              <p>{{ $t('service.custome_service1') }}</p>
              <p>{{ $t('service.custome_service2') }}</p>
              <p>{{ $t('service.custome_service3') }}</p>
              <p>{{ $t('service.custome_service4') }}</p>
            </div>
          </div>
          <div class="arrow"></div>
          <div class="step">
            <div class="icon icon2"></div>
            <div class="title">{{ $t('service.feature_confirmation') }}</div>
            <div class="tips">
              <p>{{ $t('service.feature_confirmation1') }}</p>
              <p>{{ $t('service.feature_confirmation2') }}</p>
              <p>{{ $t('service.feature_confirmation3') }}</p>
              <p>{{ $t('service.feature_confirmation4') }}</p>
            </div>
          </div>
          <div class="arrow"></div>
          <div class="step">
            <div class="icon icon3"></div>
            <div class="title">{{ $t('service.product_design') }}</div>
            <div class="tips">
              <p>{{ $t('service.product_design1') }}</p>
              <p>{{ $t('service.product_design2') }}</p>
              <p>{{ $t('service.product_design3') }}</p>
              <p>{{ $t('service.product_design4') }}</p>
            </div>
          </div>
          <div class="arrow"></div>
          <div class="step">
            <div class="icon icon4"></div>
            <div class="title">{{ $t('service.program_dev') }}</div>
            <div class="tips">
              <p>{{ $t('service.program_dev1') }}</p>
              <p>{{ $t('service.program_dev2') }}</p>
              <p>{{ $t('service.program_dev3') }}</p>
              <p>{{ $t('service.program_dev4') }}</p>
              <p>{{ $t('service.program_dev5') }}</p>
            </div>
          </div>
          <div class="arrow"></div>
          <div class="step">
            <div class="icon icon5"></div>
            <div class="title">{{ $t('service.testing') }}</div>
            <div class="tips">
              <p>{{ $t('service.testing1') }}</p>
              <p>{{ $t('service.testing2') }}</p>
              <p>{{ $t('service.testing3') }}</p>
              <p>{{ $t('service.testing4') }}</p>
              <p>{{ $t('service.testing5') }}</p>
            </div>
          </div>
          <div class="arrow"></div>
          <div class="step">
            <div class="icon icon6"></div>
            <div class="title">{{ $t('service.complete') }}</div>
            <div class="tips">
              <p>{{ $t('service.complete1') }}</p>
              <p>{{ $t('service.complete2') }}</p>
              <p>{{ $t('service.complete3') }}</p>
              <p>{{ $t('service.complete4') }}</p>
            </div>
          </div>
        </div>
        <div class="communicate-now"><router-link to="contact-us">COMMUNICATE NOW</router-link></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Service',
  data () {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
