<template>
  <div class="pc-container case-detail-container">
    <my-header :fixed="true" :white="true"></my-header>
    <div class="case-detail-content">
      <div class="title">{{ $t("index.tmall") }}</div>
      <div class="sub-title">{{ $t("index.ecommerce") }}</div>
      <div class="decorate"></div>
      <div class="main-image">
        <img src="../../assets/images/yuebao_img1.jpg" alt />
      </div>

      <div class="title">
        <div class="num">PRESENTATION</div>
        <div class="text">{{ $t("tmall.page_diplay") }}</div>
      </div>
      <div class="main-desc">
        <div class="img-wrapper">
          <img src="../../assets/images/yuebao_img2.jpg" alt />
        </div>
        <div class="info">
          <h4>{{ $t("index.ecommerce") }}</h4>
          <p>{{ $t("tmall.ecommerce_des")}}</p>
          <h4>{{ $t("tmall.cate")}}</h4>
          <p>{{ $t("tmall.ecommerce_des2")}}</p>
          <h4>{{ $t("tmall.liability")}}</h4>
          <p>{{ $t("tmall.liability_des")}}</p>
          <h4>{{ $t("tmall.language")}}</h4>
          <p>{{ $t("tmall.language_des")}}</p>
        </div>
      </div>

      <div class="detail-images">
        <img src="../../assets/images/yuebao_img3.jpg" alt />
      </div>
      <div class="other-info">
        <div>
          <p>{{ $t("tmall.feture") }}</p>
          <h4>{{ $t("tmall.Problem") }}</h4>
          <p>{{ $t("tmall.problem_des") }}</p>
        </div>
        <div>
          <h4>{{ $t("tmall.solution") }}</h4>
          <p>{{ $t("tmall.solution_des") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Yuebao",
  data() {
    return {};
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
