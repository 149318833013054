<template>
  <div class="header-container" :class="{fixed: fixed, white:white}">
    <header>
      <div class="logo">
        <img src="../../assets/images/efun_logo.png" alt />
      </div>
      <div class="nav">
        <router-link to="/index">{{ $t('index.Home') }}</router-link>
        <router-link to="/case">{{ $t('index.Cases') }}</router-link>
        <router-link to="/service">{{ $t('index.Service') }}</router-link>
        <router-link to="/about-us">{{ $t('index.About') }}</router-link>
        <router-link to="/contact-us">{{ $t('index.Contact') }}</router-link>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: ["fixed", "white"],
  data() {
    return {};
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.header-container {
  height: 84px;
  font-size: 14px;
  color: #666;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }

  &.white {
    background: #fff;
  }

  header {
    padding-top: 12px;
    margin: 0 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      text-align: center;
      line-height: 80px;
      img {
        width: 60px;
      }
    }

    .nav {
      display: flex;

      a {
        padding-bottom: 5px;
        margin-left: 80px;
        text-decoration: none;
        color: #666;

        &.router-link-active {
          color: #1a3490;
          border-bottom: #1a3490 2px solid;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
