module.exports = {
  service: {
    title: "Provide excellent software development services",
    h5_dev: "HTML5 development",
    h5_dev_des:
      "H5 event marketing is valued by more and more companies, an excellent H5 event page can bring huge traffic to enterprise products",
    web_dev: "Website development",
    web_dev_des:
      "Professional customized website system, such as corporate official website, e-government, industry portal, enterprise mall, e-commerce platform, online education, online payment, etc.",
    app_dev: "APP development",
    app_dev_des:
      "Provide professional customized development of applications for IOS and Android systems, support iPhone, iPad, Android phones, Android tablets, and cover native APP development, etc",
    software_dev: "Software system",
    software_dev_des:
      "Windows, Mac OS desktop application software, providing a powerful engine for collaborative office and business operations",
    management_dev: "Management system",
    management_dev_des:
      "According to your business process, professional tailor-made management software, including customer management, project management, ERP, production management, sales management, OA office, education and training, logistics and warehousing and other system software",
    it_solution: "Industry IT Solutions",
    it_solution_des:
      "Provide a complete set of system development solutions from business demand analysis, system design, software development, and post-operation and maintenance, so that your company can focus on market operations",
    service_process_des:
      "Our service is not a point of work, but a chain of systems",
    custome_service: "Customer service",
    custome_service1: "Create customer profile",
    custome_service2: "understand basic needs",
    custome_service3: "Customer Resource Allocation",
    custome_service4: "Regular return visits from customer service",
    feature_confirmation: "Feature confirmation",
    feature_confirmation1: "Risk prediction",
    feature_confirmation2: "Determine the scope of services",
    feature_confirmation3: "Define Outcome Standards",
    feature_confirmation4: "Publish Implementation Costs,",
    product_design: "Product design",
    product_design1: "Concept Design",
    product_design2: "Scene simulation",
    product_design3: "Prototyping",
    product_design4: "Prototype review",
    program_dev: "Program development",
    program_dev1: "Functional decomposition",
    program_dev2: "Front-end development",
    program_dev3: "Database Design",
    program_dev4: "Background logic",
    program_dev5: "Coding",
    testing: "Test",
    testing1: "BUG modification",
    testing2: "Performance Testing",
    testing3: "UE test",
    testing4: "Safety test",
    testing5: "Project review summary",
    complete: "Complete online",
    complete1: "Functional training",
    complete2: "Data monitoring",
    complete3: "Daily promotion",
    complete4: "Regular return visit"
  },
  index: {
    Home: "Home",
    Cases: "Cases",
    Service: "Service",
    About: "About Us",
    Contact: "Contact us",
    swiper_des1:
      "The Internet connects everything and builds a convenient life for human beings",
    swiper_des2:
      "Comprehensive IT solution proposal, planning, design and development",
    swiper_des3:
      "Full platform Win, Mac, Linux, Android, Iphone research and development",
    banner1_des: "Get the right solution for your business plan and budget",
    banner1_des2: "Expand the value of people, full of hope for the future",
    tmall: "T-Mall",
    tmall_des:
      "T-Mall uses the unique S2b2C model to open up the brand's inventory API, digitize and picture the brand inventory, and then provide it to distributors through the Yuebao APP for distribution",
    e_game: "E-Game City",
    e_game_des:
      "The arcade classic games that have been popular for more than ten years - Monopoly, Fishing Master, Fruit Machine, etc., meet the entertainment needs anytime and anywhere.",
    poi_des:
      "A powerful social tool product in the vertical field. The product's function, interaction and visual design meet the needs of users and reflect the core of the product's two-dimensional culture.",
    yoyo_des:
      "The product brings users and fans a higher level of user experience through scene-based voice live broadcast, meets users' pan-entertainment needs and a new social operation method.",
    company_intro: "Company Profile",
    company_intro_des:
      "Efun has successfully helped customers deploy mobile applications and expand in the market through technological innovation and product development, as well as combining existing traditional standard processes.",
    company_intro_des2:
      "Brand communication has achieved great success. The brand comes from professionalism, our core service is through scientific and effective, international design and brand management.",
    company_intro_des3:
      "Help customers build an excellent brand system and establish an excellent brand image belonging to Chinese companies worldwide",
    company_intro_des4:
      "Accurately understand customer needs; strictly control the quality process to ensure project cycle; track project progress in time, quickly handle project quality objections, and meet customer expectations and requirements for projects",
    company_intro_des5:
      "Establish a customer-oriented service concept and make customers 100% satisfied is our work goal. From the perspective of customers, grasp the mood and needs of customers, and think of problems for customers in advance",
    company_intro_des6:
      "Deal with problems in the bud. Grow together with customers, continuously improve products and services, and help customer companies continue to develop",
    custome_review: "Customer Reviews",
    custome_review1:
      "Efun is one of the most reliable outsourcing service companies I have come across. If you are looking for an outsourcing service provider, you can chat with Efun!",
    custome_review2:
      "To evaluate the strength of a software outsourcing company, it depends on the company's development technology strength, whether it has excellent technology to develop various app software. Efun is still good, if you check it yourself",
    custome_review3:
      "If you look at the team at this company, you'll see what I'm saying is true.",
    ecommerce: "e-commerce"
  },
  about: {
    dev_team: "Professional R&D team",
    dev_team_des: "88% R&D team",
    teaml_level: "High-level talent structure",
    teaml_level_des: "90% undergraduate degree or above",
    background: "Senior industry background",
    background_des: "92% come from well-known Internet companies",
    experience: "Rich project practical experience",
    experience_des: "More than 95% have project experience",
    service_quality: "Super high quality of service",
    service_quality_des: "High code quality and solution quality",
    ability: "First-class technical ability",
    ability_des:
      "Multi-industry, multi-field, multi-type, full-process development",
    management: "Super management ability",
    management_des: "Project quality management, project cycle management",
    communication: "Efficient communication with customers",
    communication_des:
      "Demand analysis, development progress feedback, customer service to solve problems",
    cooperative: "Cooperative customers are well-known",
    cooperative_des:
      "The company has a high evaluation and is recommended to other companies that need outsourcing services",
    project_exp: "Rich project experience",
    project_exp_des: "Develop a wide range of products"
  },
  contact: {
    service_time: "Reception hours: 9:30 to 18:00 on weekdays",
    service_tips:
      "If you have any questions or concerns, please feel free to contact us, and the person in charge will contact you after confirming the content of the consultation"
  },
  tmall: {
    page_diplay: "Page display",
    ecommerce: "e-commerce",
    ecommerce_des: "S2B2C e-commerce platform",
    ecommerce_des2:
      "E-commerce, inventory management software, online crowdsourcing distribution platform",
    cate: "Category",
    liability: "Limitation of Liability",
    liability_des:
      "E-commerce, inventory management software, online crowdsourcing distribution platform",
    language: "language",
    language_des:
      "Visual layer (HTML5, CSS3, Less, React, Vue), management layer (JAVA, swift, JDK7, Mysql5.6), control layer (Android, iOS, Winform), hardware environment (windows10), cluster system (Unix)",
    feture:
      "Through the unique S2b2C model, the brand inventory API is opened up, and the brand inventory is digitized and pictured, and then provided to distributors through the Yuebao APP for distribution.",
    Problem: "Problem",
    problem_des:
      "The e-commerce field has been dominated by traditional comprehensive e-commerce platforms for many years. Although there are many opportunities for vertical groups and categories, e-commerce entrepreneurs are still struggling with expensive traffic.",
    solution: "Solution",
    solution_des:
      "Social applications have a large number of users, occupying more than half of users' online usage time, and have reached a large number of user groups that traditional e-commerce has not effectively covered. We develop a crowdsourcing distribution platform to enable brand merchants to reach consumers with higher cost performance requirements through social channels."
  },
  egame: {
    title: "E-Game City",
    game_plat: "gaming platform",
    game_plat_des: "Integrated video game platform",
    cate_des: "games, casual games",
    liability_des: "planning, design, development, operation",
    feture:
      "The arcade classic games that have been popular for more than ten years - Monopoly, Fishing Master, Fruit Machine, etc., meet the entertainment needs anytime and anywhere.",
    problem_des:
      "Contemporary young people are under great social pressure and need to adjust their time for leisure and entertainment. However, many games are difficult to learn and take a long time. Only leisure and luck-like casual games that take fragments of time can make people relax.",
    solution_des:
      "Returning the game design to the classics, the game type happens to be the childhood memories of contemporary young people, which can achieve a great decompression effect."
  },
  POI: {
    title: "ACGN",
    community: "ACGN community",
    community_des: "ACGN cultural community",
    community_des2: "ACGN, social, pan-entertainment",
    liability_des: "planning, design, development, operation",
    feture:
      "A powerful social tool product in the vertical field. The product's function, interaction and visual design meet the needs of users and reflect the core of the product's two-dimensional culture.",
    problem_des:
      "ACGN lovers can't easily find new episodes and two-dimensional information, and it is difficult to find fellow fans to discuss their favorite two-dimensional content.",
    solution_des:
      "POI takes the ACGN culture as the core, and integrates unique cultures such as COS, fan drama support, and two-dimensional character support, and establishes a mechanism to encourage users to create and participate in discussions."
  },
  yoyo: {
    title: "Voice broadcast",
    app_des: "Voice Live Social Software",
    cate_des: "Social, voice broadcast, pan-entertainment",
    liability_des: "planning, design, development, operation",
    feture:
      "The product brings users and fans a higher level of user experience through scene-based voice live broadcast, meets users' pan-entertainment needs and a new social operation method.",
    problem_des:
      'Young people start to understand the screen from their mobile phones, and they are more influenced by the two-dimensional culture when they are exposed to the mobile Internet than PCs. There is a strong demand for "expansion" to make friends, and the live broadcast platform on the PC side cannot meet social needs.',
    solution_des:
      "Construct a pan-entertainment social platform in the form of live broadcast + game + social interaction to meet the one-stop social needs of young users"
  }
};
