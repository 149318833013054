<template>
  <div class="footer-container">
    <footer>
      <div class="website">
        <div class="title">Efun.tech</div>
        <div class="nav">
          <router-link to="/case">{{ $t("index.Cases") }}</router-link>
          <router-link to="/service">{{ $t("index.Service") }}</router-link>
          <router-link to="/about-us">{{ $t("index.About") }}</router-link>
          <router-link to="/contact-us">{{ $t("index.Contact") }}</router-link>
        </div>
        <div class="right">Copyright &#169 EFUN PTE. LTD.</div>
      </div>
      <div class="media">
        <div class="title">社交媒体</div>
        <div class="media-options">
          <div class="media-option"><img src="../../assets/images/google.png" alt=""></div>
          <div class="media-option"><img src="../../assets/images/twitter.png" alt=""></div>
          <div class="media-option"><img src="../../assets/images/facebook.png" alt=""></div>
          <div class="media-option"><img src="../../assets/images/ins.png" alt=""></div>
          <div class="media-option"><img src="../../assets/images/twitter.png" alt=""></div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .footer-container{
    padding: 40px 80px;
    height: 214px;
    background: #F5F5F5;
    color: #B3B3B3;
    font-size: 20px;
    box-sizing: border-box;

    footer{
      display: flex;
      justify-content: space-between;
      align-items: center;

      .website{

        .nav{
          margin: 40px 0;
          display: flex;
          font-size: 14px;

          a{
            margin-right: 80px;
            color: #546B99;
          }
        }
      }

      .media{
        display: none;

        .media-options{
          margin-top: 50px;
          width: 520px;
          display: flex;
          justify-content: space-between;

          .media-option{
            width: 64px;
            height: 64px;
            border-radius: 50%;

            img{
              width: 64px;
              height: 64px;
            }
          }
        }
      }
    }
  }
</style>
