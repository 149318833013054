<template>
  <div class="pc-container case-detail-container">
    <my-header :fixed="true" :white="true"></my-header>
    <div class="case-detail-content yoyo-detail-content">
      <div class="title">YOYO</div>
      <div class="sub-title">{{ $t("yoyo.title") }}</div>
      <div class="decorate"></div>
      <div class="main-image">
        <img src="../../assets/images/yoyo_img1.jpg" alt />
      </div>

      <div class="title">
        <div class="num">PRESENTATION</div>
        <div class="text">{{ $t("tmall.page_diplay") }}</div>
      </div>
      <div class="main-desc">
        <div class="img-wrapper">
          <img src="../../assets/images/yoyo_img2.jpg" alt />
        </div>
        <div class="info">
          <h4>{{ $t("yoyo.title") }}</h4>
          <p>{{ $t("yoyo.app_des") }}</p>
          <h4>{{ $t("yoyo.app_des") }}</h4>
          <p>{{ $t("yoyo.liability_des") }}</p>
          <h4>{{ $t("tmall.liability") }}</h4>
          <p>{{ $t("tmall.liability_des") }}</p>
          <h4>{{ $t("tmall.language") }}</h4>
          <p>{{ $t("tmall.language_des") }}</p>
        </div>
      </div>

      <div class="detail-images">
        <img src="../../assets/images/yoyo_img3.jpg" alt />
      </div>
      <div class="other-info">
        <div>
          <p>{{ $t("yoyo.feture") }}</p>
          <h4>{{ $t("tmall.Problem") }}</h4>
          <p>{{ $t("yoyo.problem_des") }}</p>
        </div>
        <div>
          <h4>{{ $t("yoyo.solution") }}</h4>
          <p>{{ $t("yoyo.solution_des") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Yuebao",
  data() {
    return {};
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
