import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/components/Index'
import Case from '@/components/Case'
import Yuebao from '@/components/case/Yuebao'
import Dianwancheng from '@/components/case/Dianwancheng'
import POI from '@/components/case/POI'
import YOYO from '@/components/case/YOYO'
import Service from '@/components/Service'
import AboutUs from '@/components/AboutUs'
import ContactUs from '@/components/ContactUs'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/index',
      name: 'Index',
      meta:{
        title:'Home'
      },
      component: Index
    },
    {
      path: '/yuebao',
      name: 'Yuebao',
      meta:{
        title:'Case'
      },
      component: Yuebao
    },
    {
      path: '/dianwancheng',
      name: 'Dianwancheng',
      meta:{
        title:'Case'
      },
      component: Dianwancheng
    },
    {
      path: '/yoyo',
      name: 'YOYO',
      meta:{
        title:'Case'
      },
      component: YOYO
    },
    {
      path: '/poi',
      name: 'POI',
      meta:{
        title:'Case'
      },
      component: POI
    },
    {
      path: '/case',
      name: 'Case',
      meta:{
        title:'案例'
      },
      component: Case
    },
    {
      path: '/service',
      name: 'Service',
      meta:{
        title:'Service'
      },
      component: Service
    },
    {
      path: '/about-us',
      name: 'AboutUs',
      meta:{
        title:'About Us'
      },
      component: AboutUs
    },
    {
      path: '/contact-us',
      name: 'ContactUs',
      meta:{
        title:'Contact'
      },
      component: ContactUs
    },
    {
      path: '*',
      redirect: '/index'
    }
  ],
  scrollBehavior: () => ({
    y: 0
  }),
})
