<template>
  <div id="app">
    <router-view/>
    <my-footer></my-footer>
  </div>
</template>

<script>
  import MyHeader from './components/common/Header'
  import MyFooter from './components/common/Footer'
export default {
  components:{MyHeader,MyFooter},
  name: 'App'
}
</script>

<style>

</style>
