<template>
  <div class="pc-container home-container case-container service-container">
    <div class="header-container fixed" :class="{white:white}">
      <header>
        <div class="logo">
          <img  src="../assets/images/efun_logo.png" alt />

        </div>
        <div class="nav">
          <router-link to="/index">{{ $t('index.Home') }}</router-link>
          <router-link to="/case">{{ $t('index.Cases') }}</router-link>
          <router-link to="/service">{{ $t('index.Service') }}</router-link>
          <router-link to="/about-us">{{ $t('index.About') }}</router-link>
          <router-link to="/contact-us">{{ $t('index.Contact') }}</router-link>
        </div>
      </header>
    </div>
    <!--banner-->
    <div class="banner">
      <el-carousel trigger="click" arrow="never" height="750px">
        <el-carousel-item>
          <div class="slogan slogan1">{{ $t("index.swiper_des1") }}</div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="slogan slogan2">{{ $t("index.swiper_des2") }}</div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="slogan slogan3">{{ $t("index.swiper_des3") }}</div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!--slogan-->
    <div class="desc">
      <p>{{ $t("index.banner1_des") }}</p>
      <p>{{ $t("index.banner1_des2") }}</p>
    </div>

    <!--应用-->
    <a class="next" href="#product-content"></a>
    <div class="detail-content" id="product-content">
      <div class="title">Selected Cases</div>
      <div class="title-decoration"></div>
      <div class="content">
        <div class="cases">
          <router-link class="case" to="yuebao">
            <div class="icon">
              <img src="../assets/images/img_case_1.jpg" alt />
            </div>
            <div class="title">{{ $t("index.tmall") }}</div>
            <div class="desc">{{ $t("index.tmall_des") }}</div>
          </router-link>
          <router-link class="case" to="poi">
            <div class="icon">
              <img src="../assets/images/img_case_3.jpg" alt />
            </div>
            <div class="title">POI</div>
            <div class="desc">{{ $t("index.poi_des") }}</div>
          </router-link>
          <router-link class="case" to="yoyo">
            <div class="icon">
              <img src="../assets/images/img_case_4.jpg" alt />
            </div>
            <div class="title">YOYO</div>
            <div class="desc">{{ $t("index.yoyo_des") }}</div>
          </router-link>
        </div>
        <router-link class="more" to="case">MORE</router-link>
      </div>
    </div>

    <!--技术-->
    <a class="next" href="#technology-content"></a>
    <div class="detail-content" id="technology-content">
      <div class="title">Service Content</div>
      <div class="title-decoration"></div>
      <div class="content">
        <div class="items">
          <div class="item">
            <div class="icon">
              <img src="../assets/images/icon-h5.png" alt />
            </div>
            <div class="title">{{ $t('service.h5_dev') }}</div>
            <div class="desc">{{ $t('service.h5_dev_des') }}</div>
          </div>
          <div class="item">
            <div class="icon">
              <img src="../assets/images/icon-website.png" alt />
            </div>
            <div class="title">{{ $t('service.web_dev') }}</div>
            <div class="desc">{{ $t('service.web_dev_des') }}</div>
          </div>
          <div class="item">
            <div class="icon">
              <img src="../assets/images/icon-app.png" alt />
            </div>
            <div class="title">{{ $t('service.app_dev') }}</div>
            <div class="desc">{{ $t('service.app_dev_des') }}</div>
          </div>
          <div class="item">
            <div class="icon">
              <img src="../assets/images/icon-desktop.png" alt />
            </div>
            <div class="title">{{ $t('service.software_dev') }}</div>
            <div class="desc">{{ $t('service.software_dev_des') }}</div>
          </div>
          <div class="item">
            <div class="icon">
              <img src="../assets/images/icon-system.png" alt />
            </div>
            <div class="title">{{ $t('service.management_dev') }}</div>
            <div class="desc">{{ $t('service.management_dev_des') }}</div>
          </div>
          <div class="item">
            <div class="icon">
              <img src="../assets/images/icon-solution.png" alt />
            </div>
            <div class="title">{{ $t('service.it_solution') }}</div>
            <div class="desc">{{ $t('service.it_solution_des') }}</div>
          </div>
        </div>
      </div>
    </div>

    <!--公司简介-->
    <a class="next" href="#company-content"></a>
    <div class="company-content" id="company-content">
      <div class="image-wrapper"></div>
      <div class="company-info">
        <div class="title">{{ $t("index.company_intro") }}</div>
        <div class="introduce">
          <p>{{ $t("index.company_intro_des") }}</p>
          <p>{{ $t("index.company_intro_des2") }}</p>
          <p>{{ $t("index.company_intro_des3") }}</p>
          <p>{{ $t("index.company_intro_des4") }}</p>
          <p>{{ $t("index.company_intro_des5") }}</p>
          <p>{{ $t("index.company_intro_des6") }}</p>

        </div>
        <router-link class="more" to="/about-us">GET MORE</router-link>
      </div>
    </div>
    <!--用户评价-->
    <div class="comment-content">
      <el-carousel trigger="click" arrow="never" height="506px">
        <el-carousel-item>
          <div class="title">{{$t("index.custome_review") }}</div>
          <div class="info">{{$t("index.custome_review1") }}</div>
          <div class="name">-John</div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="title">{{$t("index.custome_review") }}</div>
          <div class="info">
            {{$t("index.custome_review2") }}
          </div>
          <div class="name">-Tom</div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="title">{{$t("index.custome_review") }}</div>
          <div class="info">{{$t("index.custome_review3") }}</div>
          <div class="name">-Jane</div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: "Index",
  data() {
    return {
      white: false
    };
  },
  watch: {
    scrollTop(val) {
      if (val <= 84) {
        this.white = false;
      } else {
        this.white = true;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.home-container {
  .header-container {
    height: 84px;
    font-size: 14px;
    color: #666;

    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;
    }

    &.white {
      background: #fff;

      header {
        .nav {
          a {
            color: #666;

            &.router-link-active {
              color: #1a3490;
              border-bottom: #1a3490 2px solid;
            }
          }
        }
      }
    }

    header {
      padding-top: 12px;
      margin: 0 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        width: 60px;
        line-height: 60px;
        text-align: center;

        img {
          width: 60px;
        }
      }

      .nav {
        display: flex;

        a {
          padding-bottom: 5px;
          margin-left: 80px;
          text-decoration: none;
          color: #fff;

          &.router-link-active {
            color: #fff;
            border-bottom: #fff 2px solid;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
