<template>
  <div class="pc-container case-container">
    <my-header :fixed="true" :white="true"></my-header>
    <div class="banner"></div>
    <div class="detail-content">
      <div class="title">Selected Cases</div>
      <div class="sub-title">Efun with technology as the core, business understanding as the driving force, excellent service to customers, with many successful cases。</div>
      <div class="title-decoration"></div>
      <div class="content">
        <div class="cases">
          <router-link class="case" to="yuebao">
            <div class="icon"><img src="../assets/images/img_case_1.jpg" alt=""></div>
            <div class="title">{{ $t("index.tmall") }}</div>
            <div class="desc">{{ $t("index.tmall_des") }}</div>
          </router-link>
          <router-link class="case" to="poi">
            <div class="icon"><img src="../assets/images/img_case_3.jpg" alt=""></div>
            <div class="title">POI</div>
            <div class="desc">{{ $t("index.poi_des") }}</div>
          </router-link>
          <router-link class="case" to="yoyo">
            <div class="icon"><img src="../assets/images/img_case_4.jpg" alt=""></div>
            <div class="title">YOYO</div>
            <div class="desc">{{ $t("index.yoyo_des") }}</div>
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: 'Case',
    data () {
      return {
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
